import './flickity';
import './contact';
import './key-figure';

var navburger = document.getElementById('navburger');
var sitenav = document.getElementById('sitenavigation');
var siteheader = document.getElementById('siteheader');
navburger.addEventListener("click", function(){
	// if ( this.classList.contains('open') ) {
	sitenav.classList.toggle('open');
	navburger.classList.toggle('open');
	siteheader.classList.toggle('open');
	// } else {
	//   sitenav.classList.toggle('open');
	//   navburger.classList.toggle('open');
	// }
});

var filterDropper = document.querySelectorAll('.dropdowntitle');
filterDropper.forEach(el => {
	el.addEventListener("click", function (event) {
		event.preventDefault();
		event.currentTarget.closest('.dropdown').classList.toggle("open");
	}, false);
});

// If page scrolled make the header menu bg white
window.addEventListener('scroll', changeMenu);

function changeMenu() {
	var scrollBarPosition = window.pageYOffset | document.body.scrollTop;
	var siteHeader = document.getElementById('siteheader');
	if (scrollBarPosition > 50) {
		siteHeader.classList.add('scrolled');
	} else {
		siteHeader.classList.remove('scrolled');
	}
}

// Init:
changeMenu();

const scrollDown = document.querySelector('.scroll-down');
const header = document.querySelector('#siteheader');
if(scrollDown) {
	scrollDown.addEventListener('click', (event) => {
		event.preventDefault();
		window.scrollTo({
			top: window.innerHeight - header.getBoundingClientRect().height,
			left: 0,
			behavior: 'smooth'
		});
	});
}
