import $ from 'jquery';

$(document).ready(() => {

	$('footer#contact').clone().removeAttr('id').addClass('footer-popover').appendTo('body');

	$('a.contact').bind('click', function (event) {
		if (window.innerWidth > 992) {
			event.preventDefault();
			$('.footer-popover').toggleClass('in');
			$(this).blur();
		}
	});

	$(document).on('click', '.footer-close a', (event) => {
		event.preventDefault();
		$('.footer-popover').removeClass('in');
	});

});
