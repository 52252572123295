import $ from 'jquery';
import inView from 'in-view';

$(document).ready(() => {
	$('.key-figure-number').each((i, item) => {
		const $item = $(item);
		let current = $item.data('start');
		$item.html(current);
	});
	inView('.key-figure-number').on('enter', (item) => {
		const $item = $(item);
		const figure = $item.data('figure');
		const step = $item.data('step');
		let current = $item.data('start');
		$item.html(current);
		setTimeout(function() {
			const timer = setInterval(() => {
				current = Number(Math.round((current + step)+'e2')+'e-2');
				$item.html(current);
				if(current >= figure) {
					clearInterval(timer);
				}
			}, 20);
		}, 500)
	});
});
